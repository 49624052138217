/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Gracias a ", React.createElement(_components.a, {
    href: "http://enlaces.entramado.net/"
  }, "los enlaces de entramad"), "o descubro una noticia muy interesante que ha compartido ", React.createElement(_components.a, {
    href: "http://asincronia.com/"
  }, "Pablo"), ", ", React.createElement(_components.a, {
    href: "http://www.xataka.com/tecnologia-en-el-coche/el-tesla-s-tiene-un-puerto-ethernet-oculto-que-permite-por-ejemplo-ejecutar-firefox"
  }, "el descubrimiento de un puerto Ethernet en el Tesla S"), ", el coche utiliza un Ubuntu para gestionar su contenido multimedia y los diseñadores le han provisto de un puerto Ethernet conectado a la red del coche, una red con 3 dispositivos, independiente de las redes más críticas del coche. Es decir que además de las interfaces y redes ya comunes en el resto de coches como ODB II o CAN, debido al auge del coche multimedia, ahora se suman al coche las redes que tradicionalmente han sido de PC. Esto a parte de un posible jailbreak del Tesla del que ya se habla implica otras cosas también interesantes, la posibilidad de ampliar a tu gusto la red tu coche, conectar los dispositivos que le vengan en gana, colocarle un punto de acceso Wifi, ", React.createElement(_components.a, {
    href: "http://fondaki.com/pasos-hacia-la-automocion-inteligente/"
  }, "comunicarte con una antena con los coches de alrededor"), ", unir tu coche a tu red de hogar… En fin, miles de posibilidades que se harán posibles, siempre que los fabricantes no nos blinden el acceso a estas redes, que es muy posible. ¿El futuro? Próximamente, en las pantallas de tu coche.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
